import request from '@/utils/request'

export function searchProgrammes(data) {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=search',
    method: 'post',
    data:data
  })
}

export function orderProgrammes(data){
    return request({
        url: 'https://ftvaccess.fr/api_stock.php?a=order',
        method: 'post',
        data:data
    })
}