<template>
    <div class="page-container">
        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start">
                        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" class="logo" />
                        <h3 class="md-title">stock.ftvaccess.fr</h3>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-badge :md-content="num_cart" >
                            <md-button class="md-icon-button" @click="showCart=true">
                                <md-icon>shopping_basket</md-icon>
                            </md-button>
                        </md-badge>
                        <md-menu md-size="auto" md-direction="bottom-end" :mdCloseOnClick="true" :mdCloseOnSelect="true">
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content>
                                <md-menu-item @click="$router.push('/password')">
                                    <span>Changer mot de passe</span>
                                    <md-icon>lock</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/confidentialite')">
                                    <span>Politique de confidentialité</span>
                                    <md-icon>policy</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/cgu')">
                                    <span>CGU</span>
                                    <md-icon>description</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/legal')">
                                    <span>Mentions légales</span>
                                    <md-icon>gavel</md-icon>
                                </md-menu-item>
                                <md-menu-item  @click="logout">
                                    <span>Déconnexion</span>
                                    <md-icon>logout</md-icon>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
                <div class="md-toolbar-row">
                    <md-field>
                        <label for="diffseur_id">Diffuseur</label>
                        <md-select v-model="filters.diffuseur_id" name="Diffuseur" id="diffuseur_id" @md-selected="queryResults" md-dense
>
                            <md-option value="">Tous</md-option>
                            <md-option v-for="dif in $store.getters.user.diffuseurs" :key="dif.id" :value="dif.id">{{dif.nom}}</md-option>
                        </md-select>
                    </md-field>
                    <md-field :md-inline="false" md-clearable>
                        <label for="nom">Nom du programme</label>
                        <md-input type="text" v-model="filters.nom" id="nom" placeholder="Nom du programme" />
                    </md-field>
                        <md-datepicker v-model="filters.date_in" md-immediately>
                            <label>Diffusion entre</label>
                        </md-datepicker>
                        <md-datepicker v-model="filters.date_out" md-immediately>
                            <label>et</label>
                        </md-datepicker>
                </div>
                <div class="md-toolbar-row">
                    <md-field md-clearable>
                        <label for="num_programme">Num Programme</label>
                        <md-input type="text" v-model="filters.num_programme" id="num_programme" placeholder="Num Programme" />
                    </md-field>
                    <md-field md-clearable>
                        <label for="num_sequence">Num Séquence</label>
                        <md-input type="text" v-model="filters.num_sequence" id="num_sequence" placeholder="Num Séquence" />
                    </md-field>
                    <md-field md-clearable>
                        <label for="lot">Lot</label>
                        <md-input type="text" v-model="filters.lot" id="lot" placeholder="Lot" />
                    </md-field>
                    <md-field md-clearable>
                        <label for="id_oscar">ID Oscar</label>
                        <md-input type="text" v-model="filters.id_oscar" id="id_oscar" placeholder="ID Oscar" />
                    </md-field>
                    <md-field md-clearable>
                        <label for="em">EM</label>
                        <md-input type="text" v-model="filters.em" id="em" placeholder="EM" />
                    </md-field>
                    <md-field md-clearable>
                        <label for="id_sherlock">ID id_sherlock</label>
                        <md-input type="text" v-model="filters.id_sherlock" id="id_sherlock" placeholder="ID Sherlock" />
                    </md-field>
                </div>
            </md-app-toolbar>
            <md-app-content class="md-scrollbar">
                <md-empty-state v-if="!searching && results.length==0"
                    md-rounded
                    md-icon="search_off"
                    md-label="Aucuns résultats"
                    md-description="Merci d'affiner vos critéres de recherche">
                </md-empty-state>
                <md-empty-state v-if="searching"
                    md-rounded
                    md-icon="search"
                    md-label="Recherche en cours"
                    md-description="Le résultats de votre recherche va être affiché dans quelques secondes">
                </md-empty-state>
                <md-content class="md-accent" v-if="!searching && num_results==1000">
                    Votre recherche est trop large. Seul les 1000 premiers résultats sont affichés. Nous vous recommandons d'affiner votre recherche.
                </md-content>
                <md-table v-if="!searching && results.length>0" v-model="results" md-sort="date_diffusion" md-sort-order="desc" md-card md-fixed-header class="resultats" >
                    <md-table-toolbar>
                        <h1 class="md-title">Résultat(s)</h1>
                    </md-table-toolbar>

                    <md-table-row slot="md-table-row" slot-scope="{ item }" v-bind:class="{selected :isInCart(item.id)}">
                        <md-table-cell md-label="Diffuseur"><img :src="'https://ftvaccess.fr/_img_diffuseurs/'+item.diffuseur+'.png'" :alt="item.diffuseur" class="diffuseur_logo" /></md-table-cell>
                        <md-table-cell md-label="Programme" md-sort-by="nom_print">{{ item.nom_print }}
                            <p><strong>Num Prog : </strong>{{item.num_programme}} |
                            <strong>Num Seq : </strong>{{item.num_sequence}} |
                            <strong>Lot : </strong>{{item.lot}} |
                            <strong>ID Oscar : </strong>{{item.id_oscar}} |
                            <strong>EM : </strong>{{item.em}} |
                            <strong>ID Sherlock : </strong>{{item.id_sherlock}}</p>
                        </md-table-cell>
                        <md-table-cell md-label="Durée" md-sort-by="duree">{{ duree(item.duree) }}</md-table-cell>
                        <md-table-cell md-label="Date de diffusion" md-sort-by="date_diffusion">{{ item.date_diffusion }}</md-table-cell>
                        <md-table-cell >
                            <md-button v-if="!isInCart(item.id)" class="md-icon-button md-green" @click="addToCart(item)">
                                <md-icon>add_shopping_cart</md-icon>
                            </md-button>
                            <md-button v-else class="md-icon-button md-accent" @click="removeFromCart(item.id)">
                                <md-icon>remove_shopping_cart</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                    </md-table>
            </md-app-content>
        </md-app>
        <md-dialog :md-active.sync="showCart" class="cart-dialog">
            <md-dialog-title>Ma commande</md-dialog-title>
            <md-content v-if="cart.length>0">
                <md-table v-if="cart.length>0" v-model="cart" md-card md-fixed-header>
                    <md-table-toolbar>
                        <h1 class="md-title">Liste des programmes séléctionnés : {{ cart.length }}</h1>
                    </md-table-toolbar>
                     <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Diffuseur"><img :src="'https://ftvaccess.fr/_img_diffuseurs/'+item.diffuseur+'.png'" :alt="item.diffuseur" class="diffuseur_logo" /></md-table-cell>
                        <md-table-cell md-label="Programme" md-sort-by="nom_print">{{ item.nom_print }}</md-table-cell>
                        <md-table-cell md-label="Durée" md-sort-by="duree">{{ duree(item.duree) }}</md-table-cell>
                        <md-table-cell md-label="Date de diffusion" md-sort-by="date_diffusion">{{ item.date_diffusion }}</md-table-cell>
                        <md-table-cell >
                            <md-button class="md-icon-button md-accent" @click="removeFromCart(item.id)">
                                <md-icon>remove_shopping_cart</md-icon>
                            </md-button>
                        </md-table-cell>
                     </md-table-row>
                </md-table>
                <md-field>
                    <label>Commentaire de commande</label>
                    <md-textarea v-model="orderComment"></md-textarea>
                    <span class="md-helper-text">Votre commentaire sera transmis avec la liste des programmes souhaités à france•tv•acces</span>
                </md-field>
            </md-content>
             <md-dialog-actions>
                <md-button class="md-accent" @click="showCart = false">Fermer</md-button>
                <md-button v-if="cart.length>0" class="md-green" @click="placeOrder">Commander</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
import _ from 'lodash';
import {searchProgrammes, orderProgrammes} from '@/api/programme.js';
export default {
    name:"stock",
    data(){
        return {
            results:[],
            cart:[],
            showCart:false,
            orderComment:'',
            loader:null,
            num_results:0,
            searching:false,
            filters:{
                diffuseur_id:null,
                nom:null,
                date_in:'',
                date_out:'',
                num_programme:null,
                num_sequence:null,
                lot:null,
                id_oscar:null,
                id_sherlock:null,
                em:null
            }
        }
    },
    computed:{
        num_cart(){
            return this.cart.length;
        }
    },
    watch:{
        'filters.date_in': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.date_out': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.nom': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.num_programme': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.num_sequence': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.lot': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.id_oscar': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.id_sherlock': _.debounce(function(){
            this.queryResults();
        },800),
        'filters.em': _.debounce(function(){
            this.queryResults();
        },800),
    },
    methods:{
        
        placeOrder(){
            this.loader=this.$loading.show();
            orderProgrammes({'comment':this.orderComment,'programmes':JSON.stringify(this.cart)})
            .then(() => {
                this.loader.hide();
                this.$alert("Votre commande a bien été envoyée.",'Success','success');
                this.orderComment=''
                this.cart=[];
                this.$localStorage.set('cart',JSON.stringify(this.cart));
                this.showCart=false
            })
            .catch(() => {
                this.loader.hide();
                this.$alert("Nous avons rencontrer un problème pour enregistrer votre commande, veuillez essayer de nouveau",'Error','error');
            })
        },
        addToCart(prog){
            if(!this.isInCart(prog.id)){
                this.cart.push(prog);
                this.$localStorage.set('cart',JSON.stringify(this.cart));
            }
        },
        removeFromCart(id){
            let removeIndex = null;
            for(let [index, prog] of this.cart.entries()){
                if(prog.id==id){
                    removeIndex = index;
                    break;
                }
            }
            if(removeIndex!==null){
                this.cart.splice(removeIndex, 1);
                this.$localStorage.set('cart',JSON.stringify(this.cart));
            }

        },
        isInCart(id){
            let ret = false;
            for(let prog of this.cart){
                if(prog.id==id){
                    ret = true;
                    break;
                }
            }
            return ret;
        },
        duree(val){
            let val2 = parseInt(val);
            let ret ='';
            let h = (val2 - val2 % 3600) /3600;
            val2 = val2 - h*3600;
            ret=h+':';
            let m = (val2 - val2%60) / 60;
            val2 = val2 - m*60;
            if(m<10){
                ret+='0';
            }
            ret+=m+':';
            if(val2<10){
                ret+='0';
            }
            ret+=val2;
            return ret;

        },
        queryResults(){
            this.searching=true;
            searchProgrammes(this.filters).then((data) => {
                this.num_results=data.length;
                this.$store.dispatch('user/setNumResults',this.num_results);
                this.results=data;
                this.searching=false;
            })
            .catch(()=>{
                this.$alert("Votre sessions a éxpiré. Veuillez vous identifier de nouveau",'Error','error');
                this.searching=false;
                this.$store.dispatch('user/logout')
                this.$router.push({path:'/login'});
            })
        },
        logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch(() => {
                this.$alert("Il n'a pas été possible de vous deconnecter",'Error','error');
            })
        },
        initLocalStorage(){
            let lsValues = this.$localStorage.get('cart');
            if(lsValues != null){
                this.cart=JSON.parse(lsValues);
            }
            else{
                //starting localStorage :
                this.$localStorage.set('cart',JSON.stringify(this.cart));
            }
        }
    },
    mounted(){
        if(this.$store.getters.user.password_change){
            this.$alert("Votre mot de passe n'a pas été changé depuis longtemps. Vous devez modifier celui-ci afin d'accéder au site.","Error","error");
            this.$router.push('/password');
        }
        else if(this.$store.getters.user.date_cgu==0){
            this.$alert("Afin d'accèder au site, vous devez accepter les conditions générales d'utilisation.","Error","error");
            this.$router.push('/accept_cgu');
        }
        this.initLocalStorage();
        this.$material.locale= {
            ...this.$material.locale,
    dateFormat: 'dd/MM/yyyy',
    firstDayOfAWeek: 1,
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    shortDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    shorterDays: ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'],
    months: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sept', 'Oct', 'Nov', 'Dec'],
    shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Juin', 'Juil', 'A', 'S', 'O', 'N', 'D'],
    cancel: 'Annuler',
    confirm: 'Ok'
        }
    }
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
img.logo{
    max-height: 50px;
}
.md-list-item-text img{
    height: 40px;
    width: auto;
    display: inline;
    margin-right: 10px;
}
.md-field{
    margin-left: 5px;
    margin-right: 5px;
}
img.diffuseur_logo{
    width: 30px;
    height: 30px;
    max-width: 30px;
}
.md-table-cell:nth-child(2){
    text-align: left;
}
.md-table-cell:nth-child(4), .md-table-head:nth-child(4){
    width: 200px;
}
.md-table-row.selected{
    background-color: lightblue;
}

</style>
<style lang="scss">
.md-field.md-theme-default > .md-icon:after{
    background-color: var(--md-theme-default-primary, #b0bec5);
}
.md-dialog.cart-dialog .md-dialog-container {
    max-width: 768px;
    padding: 5px;
  }
.md-table.resultats .md-table-content{
    height: calc(100vh - 350px) !important;
    max-height: calc(100vh - 350px) !important;
}
</style>